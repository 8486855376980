import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useUserContext } from 'context'
import css from '@styled-system/css'

import { Flex, ResponsiveSVG, breakpoints, P } from 'ui/acro'
import { YogaMat } from 'images/latest'
import { H2 } from 'ui/bend'

import { useIsDesktop } from 'hooks'
import ClassResultCard from 'components_v2/Search/CardStyles/ClassResultCard'
import Carousel from 'components_v2/Home/SignedIn/common/components/Carousel/Carousel'
import { axios } from 'api'
import { useNavbarContext } from './data/_store'
import NoPracticeText from './NoPracticeText'
import { storeEventInLocalStorage } from 'gtm/pushEvents'

const SessionTitle = styled(H2)(() =>
  css({
    color: '#000',
    fontSize: '1.75rem',
    textTransform: 'uppercase',
    margin: 0
  })
)

const NavTitle = styled(P)(() =>
  css({
    fontSize: '1rem',
    letterSpacing: '0.022rem',
    margin: 0,
    color: '#3B3B3B',
    '@media only screen and (max-width: 1200px)': {
      fontWeight: '600',
      lineHeight: '1.5rem'
    }
  })
)

const MyPracticeWrapper = styled(Flex)(() =>
  css({
    justifyContent: 'space-between',
    padding: '1.5rem 3.75rem',
    '@media only screen and (max-width: 1200px)': {
      flexDirection: 'column',
      padding: 0
    }
  })
)

const LeftDropDownSection = styled.div`
  flex: 1;
`
const RightDropDownSection = styled.div`
  flex: 1;
  border-left: 1px solid #cccccc;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media only screen and (max-width: 1200px) {
    border-left: 0;
    padding: 0;
    border-top: 1px solid #cccccc;
    margin-top: 1rem;
    padding-top: 1rem;
  }
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  letter-spacing: 0.022rem;
  margin-top: 1.5rem;
  a {
    font-family: 'proxima-nova';
  }
`

const Text = styled(Flex)(() =>
  css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.22rem',
    fontFamily: 'Arquitecta',
    fontWeight: '900',
    letterSpacing: '0.07rem',
    textTransform: 'uppercase'
  })
)

const MyPractice = () => {
  const { user } = useUserContext()
  const isDesktop = useIsDesktop()
  const { state, actions } = useNavbarContext()
  const [recentlySaved, setRecentlySaved] = useState(state?.recentlySaved || [])

  useEffect(() => {
    axios.get('/my_practice/saved?page=1&per_page=3').then(({ data }) => {
      const refinedData = data.items?.map((item) => ({
        title: item.title,
        thumbnailImage: item?.thumbnailImage,
        humanDuration: item?.humanDuration,
        classesCount: item?.classesCount,
        coaches: (function () {
          const coaches = item?.coaches
          const refinedCoaches = coaches?.reduce((coaches, item) => {
            return [`${item.firstName} ${item.lastName}`, ...coaches]
          }, [])
          return refinedCoaches
        })(),
        category: item?.category,
        subcategory: item?.subcategory,
        difficultyLevel: item?.difficultyLevel,
        url: item.url,
        id: item.id
      }))
      setRecentlySaved(refinedData || [])
      actions?.setRecentlySaved(refinedData || [])
    })
  }, [])

  const handleTabClick = (event) => {
    storeEventInLocalStorage({ event, location: 'navigation' })
  }

  return (
    <div>
      {!isDesktop && (
        <Text>
          <ResponsiveSVG SVG={YogaMat} width='1.7rem' height='1.5rem' />
          My Practice
        </Text>
      )}
      <MyPracticeWrapper>
        <LeftDropDownSection>
          <SessionTitle>{`Hi, ${user.firstName}`}</SessionTitle>
          <LinkContainer>
            <a
              href={'/my/practice?tab=completed'}
              onClick={() => handleTabClick('completed_classes_click')}
            >
              Completed Classes
            </a>
            <a
              href={'/my/practice?tab=classes'}
              onClick={() => handleTabClick('saved_classes_click')}
            >
              Saved Classes
            </a>
            <a
              href={'/my/practice?tab=series'}
              onClick={() => handleTabClick('saved_series_click')}
            >
              Saved Series
            </a>
            <a
              href={'/user/playlists'}
              onClick={() => handleTabClick('my_playlists_click')}
            >
              My Playlists
            </a>
          </LinkContainer>
        </LeftDropDownSection>
        <RightDropDownSection>
          <SessionTitle>RECENTLY SAVED</SessionTitle>
          {recentlySaved?.length > 0 ? (
            <>
              <NavTitle>From your saved classes & series</NavTitle>
              <Carousel
                card={ClassResultCard}
                carouselTitle={'WorkoutRecommendationsClasses'}
                data={recentlySaved}
                mx={['16px', '16px', '16px', '0px']}
                breakpoints={{
                  0: {
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                  },
                  320: {
                    slidesPerView: 1.8,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                  },
                  375: {
                    slidesPerView: 1.8,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                  },
                  [breakpoints[1]]: {
                    slidesPerView: 3.4,
                    spaceBetween: 20,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                  },
                  768: {
                    slidesPerView: 3.4,
                    spaceBetween: 20,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                  },
                  [breakpoints[2]]: {
                    slidesPerView: 3,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                  }
                }}
              />
            </>
          ) : (
            <NoPracticeText />
          )}
        </RightDropDownSection>
      </MyPracticeWrapper>
    </div>
  )
}

export default MyPractice
