import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'

import { breakpoints as Breakpoints, CarouselContainer } from 'ui/acro'
import { useSwiper } from 'hooks'
import CarouselNextButton from './CarouselNextButton'
import CarouselPrevButton from './CarouselPrevButton'

const Carousel = ({
  data,
  slug,
  carouselTitle,
  slidesPerView = 3,
  card,
  breakpoints = {},
  location,
  eventName
}) => {
  const { getSwiperProps, goPrev, goNext, isBeginning, isEnd } = useSwiper()

  return (
    <CarouselContainer carouselTitle={carouselTitle}>
      <Swiper
        {...getSwiperProps({
          lazy: true,
          slidesPerGroup: 1,
          spaceBetween: 20,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          watchOverflow: true,
          freeMode: true,
          freeModeSticky: true,
          slidesPerView,
          breakpoints: {
            [Breakpoints[0]]: {
              spaceBetween: 20
            },
            [Breakpoints[1]]: {
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0
            },
            [Breakpoints[2]]: {
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0
            },
            ...breakpoints
          }
        })}
      >
        {data &&
          data.map((item, index) => (
            <SwiperSlide
              tag={card}
              key={index}
              carouselItem={true}
              position={index + 1}
              htmlId={carouselTitle + (index + 1)} // using htmlId because some cards already have an ID coming from the API - this is purely for heap tracking
              eventName={eventName}
              location={location}
              {...item}
            />
          ))}
      </Swiper>

      <CarouselPrevButton
        handleClick={goPrev}
        disabled={isBeginning}
        display={['none', null, null, 'inherit']}
        id={`home-${slug}-carousel-prev`}
        aria-label='Previous'
      />
      <CarouselNextButton
        handleClick={goNext}
        disabled={isEnd}
        display={['none', null, null, 'inherit']}
        id={`home-${slug}-carousel-next`}
        aria-label='Next'
      />
    </CarouselContainer>
  )
}

Carousel.propTypes = {
  layout: PropTypes.string,
  carouselTitle: PropTypes.string,
  slug: PropTypes.string,
  card: PropTypes.object
}

export default Carousel
