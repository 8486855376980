import React, { Fragment } from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'

import { useUserContext } from 'context'
import { useNavbarContext } from '../../common/data/_store'

import { Flex, ResponsiveSVG } from 'ui/acro'
import { ChevronRightIcon } from 'components_v2/Icons'
import { YogaMat, AloMovesLogo } from 'images/latest'

import { topCategory } from '../../common/data/topCategory'

import NotificationBadge from '../../Desktop/RightContent/Profile/NotificationBadge'
import MobileMenuLink from '../common/MobileMenuLink'
import { HalfDivider } from '../common/Dividers'
import AppStoreComponent from '../../common/AppStoreComponent'

export const MobileMenuPrimaryPage = styled(Flex)`
  ${css({
    flexDirection: 'column',
    pb: 'calc(15px + env(safe-area-inset-bottom))'
  })}
`

const MobileMenuLinkWrapper = styled(MobileMenuLink)(() =>
  css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    justifyContent: 'space-between',
    height: '4.5rem',
    borderTop: '1px solid #CCC',
    '&:last-of-type': {
      borderBottom: '1px solid #CCC',
      marginBottom: '15px'
    }
  })
)

const MobileMenuPrimary = (props) => {
  const { user } = useUserContext()
  const { state, actions } = useNavbarContext()

  return (
    <MobileMenuPrimaryPage>
      <div>
        {user && (
          <MobileMenuLinkWrapper
            id='nav_classes_mobile'
            aria-label='My pratice'
            onClick={() => {
              window.dataLayer.push({ event: 'my_practice_click' })
              props.pageSelected('my_practice')
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ResponsiveSVG SVG={YogaMat} width='1.7rem' height='1.5rem' />
              My Practice
            </div>
            <ChevronRightIcon color='grey' />
          </MobileMenuLinkWrapper>
        )}

        {topCategory.map((item) => (
          <MobileMenuLinkWrapper
            id={`nav_${item.value}_mobile`}
            key={item.key}
            aria-label={item.value}
            onClick={() => props.pageSelected(item.key)}
          >
            {item.value}
            <ChevronRightIcon color='grey' />
          </MobileMenuLinkWrapper>
        ))}
      </div>

      {user && (
        <Fragment>
          <MobileMenuLink
            href={`/users/${user.username}`}
            aria-label='Profile Menu'
            id='nav_profile_mobile'
          >
            My Profile
          </MobileMenuLink>
          <MobileMenuLink
            href='/notifications'
            onClick={actions.clearNotifications}
            id='nav_notifications_mobile'
            position='relative'
          >
            Notifications
            {state.notificationCount > 0 && <NotificationBadge ml='10px' />}
          </MobileMenuLink>
          <MobileMenuLink href='/community' id='nav_community_mobile'>
            Community
          </MobileMenuLink>
          <HalfDivider />
        </Fragment>
      )}
      <MobileMenuLink
        href='/alo_shop_si_web'
        id='alo_shop_si_web'
        className='unstyled'
        rel='noopener noreferrer'
        target='_blank'
      >
        Shop
        <ResponsiveSVG
          marginLeft={'0.5rem'}
          SVG={AloMovesLogo}
          width='1.7rem'
          height='1.5rem'
        />
      </MobileMenuLink>

      <MobileMenuLink
        href='/gift?utm_source=si_nav'
        id='alo_shop_si_web'
        className='unstyled'
        rel='noopener noreferrer'
        target='_blank'
      >
        gift cards
      </MobileMenuLink>

      <HalfDivider />

      {user && (
        <Fragment>
          <MobileMenuLink
            href='/settings/profile'
            id='nav_settings_mobile'
            aria-label='Settings'
          >
            Settings
          </MobileMenuLink>
          <MobileMenuLink
            href='/referrals?traffic_source=site_menu_mobile'
            id='nav_share_mobile'
            className='unstyled'
          >
            Refer A Friend
          </MobileMenuLink>
          <MobileMenuLink
            href='/support'
            id='nav_gethelp_mobile'
            className='unstyled'
          >
            Get Help
          </MobileMenuLink>
          <MobileMenuLink
            href='/signout'
            id='nav_logout_mobile'
            className='unstyled'
          >
            Sign Out
          </MobileMenuLink>
        </Fragment>
      )}

      {!user && (
        <Fragment>
          <MobileMenuLink href='/signin' id='nav_signin_mobile'>
            Sign In
          </MobileMenuLink>
          <MobileMenuLink href='/surveys/onboarding' id='nav_subscribe_mobile'>
            Try For Free
          </MobileMenuLink>
        </Fragment>
      )}

      <HalfDivider />
      <AppStoreComponent style={{ padding: '0 15px' }} />
    </MobileMenuPrimaryPage>
  )
}

export default MobileMenuPrimary
