window.dataLayer = window.dataLayer || []

export const unlock_membership_event = (location) => {
  window.dataLayer?.push({
    event: 'unlock_membership_select',
    membership_location: location
  })
}

export const payment_membership_chosen = (type, promo_code) => {
  window.dataLayer?.push({
    event: 'payment_membership_chosen',
    sub_plan: type,
    promo_code: promo_code || null
  })
}

export const survey_started_event = () => {
  window.dataLayer?.push({
    event: 'new_survey_started'
  })
}

export const survey_skipped_event = () => {
  window.dataLayer?.push({
    event: 'new_survey_skipped'
  })
}

export const survey_finished_event = () => {
  window.dataLayer?.push({
    event: 'new_survey_finished'
  })
}

export const code_redeemed_event = (gtm_event_params, type) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'code_redeemed',
      type: type,
      promo_code: gtm_event_params.promo_code,
      unique_code: gtm_event_params.unique_code || ''
    })
  }
}

// method param accepts: [email, facebook, apple]
export const sign_up_initiated_event = (method) => {
  window.dataLayer?.push({
    event: 'sign_up_initiated',
    method: method
  })
}

const format_filter_values = (type, value, options) => {
  let filterValue
  let filterType

  switch (type) {
    case 'instructors':
      filterValue = value.user?.name
      filterType = 'instructor_filtered'
      break
    case 'styles':
      // Temporal solution for GA4 phase one
      // The filter value selected by the user is supposed to be stored in value.slug.
      // However, due to an issue with iOS not sending the value correctly,
      // we are sending the primary category instead.
      filterValue =
        options.root && options.root.slug ? options.root.slug : value.slug
      filterType = 'style_filtered'
      break
    case 'intensities':
      filterValue = value.level
      filterType = 'intensity_filtered'
      break
    case 'difficulties':
      filterValue = value
      filterType = 'difficulty_filtered'
      break
    case 'duration':
      filterValue = `${value.min}-${value.max}`
      filterType = 'duration_filtered'
      break
    case 'focus_area':
      filterValue = value.level
      filterType = 'focus_area_filtered'
      break
    case 'equipment':
      filterValue = value.level
      filterType = 'equipment_filtered'
      break
    case 'yoga':
    case 'fitness':
    case 'mindfulness':
    case 'wellness':
    case 'skills':
      filterValue = value.level
      filterType = 'style_filtered'
      break
    default:
      filterValue = value
      filterType = type
      break
  }
  return {
    formattedValue: filterValue,
    formattedType: filterType
  }
}

export const class_series_finder_filter_event = (
  type,
  value,
  options = null
) => {
  const { formattedType } = format_filter_values(type, value, options)

  if (window.dataLayer) {
    window.dataLayer.push({
      event: formattedType,
      filter_value: value
    })
  }
}

export const sort_by_event = (location, value) => {
  let filterValue

  switch (value) {
    case 'az':
      filterValue = 'a-z'
      break
    case 'date':
      filterValue = 'newest'
      break
    case 'rank':
      filterValue = 'popular'
      break
    case 'relevance':
      filterValue = 'relevant'
      break
    default:
      filterValue = value
  }
  // This event has to trigger with just these two values
  // even if the component calling it is located in other parts of the site.
  if (window.dataLayer && (location === 'classes' || location === 'series')) {
    window.dataLayer.push({
      event: 'sorted_by',
      location: location === 'classes' ? 'class_finder' : 'series_finder',
      filter_value: filterValue
    })
  }
}

export const welcome_event_viewed = () => {
  window.dataLayer?.push({
    event: 'Welcome_page_viewed'
  })
}

export const welcome_event_tapped = () => {
  window.dataLayer?.push({
    event: 'Welcome_page_tapped'
  })
}

export const home_signed_in_billboard_click_event = (title, position) => {
  window.dataLayer?.push({
    event: 'home_signed_in_billboard_click',
    campaign_name: title,
    position: position
  })
}

export const gift_card_added_event = () => {
  window.dataLayer.push({
    event: 'gift_card_added'
  })
}

export const gift_card_interest_event = (location) => {
  window.dataLayer.push({
    event: 'gift_card_interest',
    location
  })
}

export const gift_card_intent_event = () => {
  window.dataLayer.push({
    event: 'gift_card_intent'
  })
}

export const casting_initiated_event = (method) => {
  window.dataLayer.push({
    event: 'casting_initiated',
    method
  })
}

export const change_practice_tab_event = (tab) => {
  const eventMap = {
    series: 'saved_series_click',
    classes: 'saved_classes_click',
    completed: 'completed_classes_click',
    playlists: 'my_playlists_click'
  }
  window.dataLayer.push({
    event: eventMap[tab] || '',
    location: 'my_practice'
  })
}

export const storeEventInLocalStorage = (eventData) => {
  let events = []
  try {
    const storage_data =
      JSON.parse(localStorage.getItem('ga4_event_list')) || []
    if (Array.isArray(storage_data)) {
      events = storage_data
    }
  } catch (e) {
    events = []
  }
  localStorage.setItem('ga4_event_list', JSON.stringify([...events, eventData]))
}

export const retrieveAndClearEvents = () => {
  let events = []
  try {
    events = JSON.parse(localStorage.getItem('ga4_event_list')) || []
  } catch (e) {
    events = []
  }
  if (events && Array.isArray(events) && events.length > 0) {
    window.dataLayer.push(...events)
    localStorage.removeItem('ga4_event_list')
  }
}
